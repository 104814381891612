<template>
  <div style="position: relative">
    <div class="row2 flex-1 overflow-y">
      <div class="row2-card" v-loading="loading">
        <!-- 表头 -->
        <div class="flex-row table-header" style="padding-left: 38px">
          <div style="width: 20%; text-align: center">名称</div>
          <div style="width: 20%">角标设置</div>
          <div style="width: 30%">排序</div>
          <div style="width: 17%">操作</div>
          <div style="width: 13%">状态</div>
        </div>
        <!-- 行数据 -->
        <div id="items">
          <div
            class="item"
            v-for="(item, i) in scriptSortList"
            :key="item.id"
            :style="{
              background: i % 2 !== 0 ? '#fff' : 'rgba(250,249,255,1)',
            }"
          >
            <!-- 商品序号 -->
            <div class="item-index">
              {{ i + 1 }}
            </div>
            <div class="flex" style="width: 100%">
              <!-- 剧本名称 -->
              <div class="item-title" style="width: 20%">
                <el-select
                  size="mini"
                  @change="scriptChange($event, item)"
                  filterable
                  v-model="item.skipUrl"
                  placeholder="请选择剧本"
                >
                  <el-option
                    v-for="itema in scriptList"
                    :disabled="scenariorepeatMap[itema.id]"
                    :key="itema.id"
                    :label="itema.scenarioName"
                    :value="itema.id"
                  >
                  </el-option>
                </el-select>
              </div>
              <div class="flex-cc" style="width: 20%">
                <el-button size="mini" @click="openEdit(item)">编辑</el-button>
              </div>
              <!-- 排序 -->
              <div class="item-message flex-cc" style="width: 30%">
                <el-button-group>
                  <el-button
                    size="mini"
                    :disabled="i === 0"
                    @click="moveScript(i, 'toFirst')"
                    >置顶</el-button
                  >
                  <el-button
                    size="mini"
                    :disabled="i === 0"
                    @click="moveScript(i, 'pre')"
                    >上移</el-button
                  >
                  <el-button
                    size="mini"
                    :disabled="i === scriptSortList.length - 1"
                    @click="moveScript(i, 'next')"
                    >下移</el-button
                  >
                </el-button-group>
              </div>

              <!-- 剧本上传日期 -->
              <div class="item-date flex-cc" style="width: 17%">
                <el-button @click="delScript(i)" type="danger" plain size="mini"
                  >删除</el-button
                >
              </div>
              <!-- 发售状态按钮 -->

              <!-- 发售状态 -->
              <div
                class="item-state"
                style="width: 13%; display: flex; justify-content: center"
              >
                <div
                  v-if="item.isAudit"
                  class="state"
                  :style="{
                    background:
                      item.isAudit === 2
                        ? 'rgba(139, 209, 203, 1)'
                        : 'rgba(248, 205, 227, 1)',
                  }"
                >
                  {{ item.isAudit === 2 ? "已过审" : "未过审" }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex-cc width100">
      <el-button
        class="add-btn"
        size="mini"
        icon="el-icon-plus"
        @click="addScript"
        >新增剧本</el-button
      >
    </div>

    <!-- 更多设置弹窗 -->
    <el-dialog title="更多设置" :visible.sync="showEditDialog" width="500px">
      <div slot="title" style="text-align: center">
        <span style="background: #eceafa; padding: 5px">{{ "更多设置" }}</span>
      </div>
      <div class="flex">
        <div class="title required">角标状态</div>
        <myswitch
          v-model="cornerModel.status"
          leftText="显示"
          rightText="隐藏"
          leftValue="1"
          rightValue="0"
        />
      </div>
      <div class="flex mt-2">
        <div class="title">角标文本</div>
        <el-input
          size="mini"
          v-model="cornerModel.content"
          style="width: 96px"
          maxlength="5"
        ></el-input>
        <span class="corner-tip">5个字以下</span>
      </div>
      <div class="flex mt-2">
        <div class="title required">角标图片</div>
        <choiceImage :viewUrl="cornerModel.pictureUrl" @surePush="surePush" />
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="showEditDialog = false">取 消</el-button>
        <el-button type="primary" @click="addCorner">保存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Sortable from "sortablejs";
import myswitch from "@compts/common/myswitch";
import choiceImage from "@compts/common/choiceImage";
export default {
  components: {
    myswitch,
    choiceImage,
  },
  props: {
    scriptSortList: {
      type: Array,
    },
  },
  data() {
    this.statusArr = [
      { label: "未发售", bg: "rgb(215, 213, 223)" },
      { label: "已发售", bg: "rgb(139, 209, 203)" },
      { label: "停售", bg: "rgb(215, 213, 223)" },
    ];
    return {
      scenariorepeatMap: {},
      scenarioAllMap: {},
      scriptIdList: [],
      scriptList: [],
      loading: false,
      bannerIndex: 0,
      scenarioModel: {
        isAudit: 0,
        searchName: "",
        time: "",
        page: 1,
        limit: 50,
      },
      bannerItem: {
        id: "",
        bannerPictureId: "", //banner图片ID
        bannerPicturePath: "", //banner图片地址
        skipUrl: "", //跳转路由
        skipType: 1, //跳转类型
        popupPictureId: "", //弹窗图片ID
        popupPicturePath: "", //弹窗图片地址
        popupContent: "", //弹窗内容
        sort: "",
        scenarioId: "",
        isAudit: "",
        time: new Date().getTime(),
      },
      cornerModel: {
        status: 0,
        pictureId: "",
        content: "",
        scenarioId: "",
        pictureUrl: "",
      },
      picturePath: "",
      list: [],
      showEditDialog: false,
    };
  },
  computed: {
    ...mapState(["scenarioList"]),
  },
  watch: {
    scriptSortList: {
      handler(v) {
        this.scenariorepeatMap = v.reduce((p, c) => {
          p[c.skipUrl + ""] = true;
          return p;
        }, {});
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    // this.$store.dispatch("getScenarioList");
    this.getScriptList();
    const el = document.getElementById("items");
    new Sortable(el, {
      animation: 150,
      delay: 0,
      ghostClass: "blue-background-class",
      onEnd: ({ newIndex, oldIndex }) => {
        const [item] = this.scriptSortList.splice(oldIndex, 1);
        this.scriptSortList.splice(newIndex, 0, item);
      },
    });
  },
  methods: {
    async addCorner() {
      if (!this.cornerModel.pictureId) {
        return this.$message.warning("请选择角标图片");
      }
      this.$delete(this.cornerModel, "pictureUrl");
      await this.$api.page.addCorner(this.cornerModel);
      this.showEditDialog = false;
    },
    async openEdit(item) {
      try {
        const res = await this.$api.page.getCorner(item.skipUrl);
        this.showEditDialog = true;
        Object.keys(res).forEach((item) => (res[item] = res[item] + ""));
        this.cornerModel = res;
      } catch (e) {
        this.showEditDialog = true;
        this.cornerModel = this.$options.data().cornerModel;
      }
      this.cornerModel.scenarioId = item.skipUrl;
      // this.picturePath = "";
    },
    surePush({ id, picturePath }) {
      this.cornerModel.pictureId = id;
      this.cornerModel.pictureUrl = picturePath;
    },
    moveScript(index, movetype) {
      const arr = this.scriptSortList;
      this._move(arr, movetype, index);
    },
    _move(arr, movetype, index) {
      const [item] = arr.splice(index, 1);
      if (movetype !== "toFirst") {
        arr.splice(movetype === "pre" ? index - 1 : index + 1, 0, item);
        return;
      }
      arr.unshift(item);
    },
    scriptChange() {
      // item.isAudit = this.scenarioAllMap[e].isAudit;
    },
    delScript(index) {
      this.$confirm("是否删除此剧本?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.scriptSortList.splice(index, 1);
      });
    },
    addScript() {
      this.scriptSortList.push(JSON.parse(JSON.stringify(this.bannerItem)));
    },
    isHavescript(arr, id) {
      return arr.findIndex((item) => item.scenarioId === id) === -1;
    },
    async getScriptList() {
      const { data } = await this.$api.shop
        .getScriptList({
          limit: 100,
          page: 1,
        })
        .finally(() => {
          this.loading = false;
        });
      this.scriptList = data;
    },
    async getScenarioAll() {
      const { data } = await this.getRequest.getScenarioAll({
        limit: -1,
        page: 1,
      });
      this.scenarioAllMap = data.reduce((pre, cur) => {
        pre[cur.id] = cur;
        return pre;
      }, {});

      this.scriptList = data;
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  width: 96px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  background: #eceafa;
  border-radius: 4px;
  position: relative;
  margin-right: 30px;
}
.corner-tip {
  font-size: 14px;
  color: #f498af;
  margin-left: 20px;
  line-height: 28px;
}
.required {
  position: relative;
  &::after {
    content: "*";
    color: red;
    position: absolute;
    top: 50%;
    right: -10px;
    transform: translateY(-50%);
  }
}
.blue-background-class {
  background: red;
}
.banner-img-choice {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  background: #fff;
  border: 1px solid #c5c0e0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #333333;
  font-family: "SourceHanSansCN-Regular, SourceHanSansCN";
}
.flex-around {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.flex {
  display: flex;
}
.flex-start {
  display: flex;
  align-items: center;
}
.add-btn {
  margin-bottom: 100px;
}
.first-title {
  color: #333333;
  font-size: 14px;
  margin-right: 20px;
}
.item-image {
  width: 149px;
  height: 56px;
  background: rgba(236, 234, 250, 1);
  border-radius: 4px;
  margin-top: 10px;
  position: relative;
  cursor: pointer;
  .left {
    position: absolute;
    top: -5px;
    left: 0;
  }
  .right {
    position: absolute;
    top: 0;
    right: 0;
    height: 20px;
    width: 54px;
    font-size: 14px;
    color: #fff;
    text-align: center;
    line-height: 20px;
    border-radius: 4px;
  }
  .item-image-mask {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgb(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 4px;
    color: #fff;
  }
}
v-deep .el-textarea__inner {
  margin: 0;
}
v-deep .suffix {
  background: #333333;
}
</style> 