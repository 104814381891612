var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"position":"relative"}},[_c('div',{staticClass:"row2 flex-1 overflow-y"},[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"row2-card"},[_vm._m(0),_c('div',{attrs:{"id":"items"}},_vm._l((_vm.scriptSortList),function(item,i){return _c('div',{key:item.id,staticClass:"item",style:({
            background: i % 2 !== 0 ? '#fff' : 'rgba(250,249,255,1)',
          })},[_c('div',{staticClass:"item-index"},[_vm._v(" "+_vm._s(i + 1)+" ")]),_c('div',{staticClass:"flex",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"item-title",staticStyle:{"width":"20%"}},[_c('el-select',{attrs:{"size":"mini","filterable":"","placeholder":"请选择剧本"},on:{"change":function($event){return _vm.scriptChange($event, item)}},model:{value:(item.skipUrl),callback:function ($$v) {_vm.$set(item, "skipUrl", $$v)},expression:"item.skipUrl"}},_vm._l((_vm.scriptList),function(itema){return _c('el-option',{key:itema.id,attrs:{"disabled":_vm.scenariorepeatMap[itema.id],"label":itema.scenarioName,"value":itema.id}})}),1)],1),_c('div',{staticClass:"flex-cc",staticStyle:{"width":"20%"}},[_c('el-button',{attrs:{"size":"mini"},on:{"click":function($event){return _vm.openEdit(item)}}},[_vm._v("编辑")])],1),_c('div',{staticClass:"item-message flex-cc",staticStyle:{"width":"30%"}},[_c('el-button-group',[_c('el-button',{attrs:{"size":"mini","disabled":i === 0},on:{"click":function($event){return _vm.moveScript(i, 'toFirst')}}},[_vm._v("置顶")]),_c('el-button',{attrs:{"size":"mini","disabled":i === 0},on:{"click":function($event){return _vm.moveScript(i, 'pre')}}},[_vm._v("上移")]),_c('el-button',{attrs:{"size":"mini","disabled":i === _vm.scriptSortList.length - 1},on:{"click":function($event){return _vm.moveScript(i, 'next')}}},[_vm._v("下移")])],1)],1),_c('div',{staticClass:"item-date flex-cc",staticStyle:{"width":"17%"}},[_c('el-button',{attrs:{"type":"danger","plain":"","size":"mini"},on:{"click":function($event){return _vm.delScript(i)}}},[_vm._v("删除")])],1),_c('div',{staticClass:"item-state",staticStyle:{"width":"13%","display":"flex","justify-content":"center"}},[(item.isAudit)?_c('div',{staticClass:"state",style:({
                  background:
                    item.isAudit === 2
                      ? 'rgba(139, 209, 203, 1)'
                      : 'rgba(248, 205, 227, 1)',
                })},[_vm._v(" "+_vm._s(item.isAudit === 2 ? "已过审" : "未过审")+" ")]):_vm._e()])])])}),0)])]),_c('div',{staticClass:"flex-cc width100"},[_c('el-button',{staticClass:"add-btn",attrs:{"size":"mini","icon":"el-icon-plus"},on:{"click":_vm.addScript}},[_vm._v("新增剧本")])],1),_c('el-dialog',{attrs:{"title":"更多设置","visible":_vm.showEditDialog,"width":"500px"},on:{"update:visible":function($event){_vm.showEditDialog=$event}}},[_c('div',{staticStyle:{"text-align":"center"},attrs:{"slot":"title"},slot:"title"},[_c('span',{staticStyle:{"background":"#eceafa","padding":"5px"}},[_vm._v(_vm._s("更多设置"))])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"title required"},[_vm._v("角标状态")]),_c('myswitch',{attrs:{"leftText":"显示","rightText":"隐藏","leftValue":"1","rightValue":"0"},model:{value:(_vm.cornerModel.status),callback:function ($$v) {_vm.$set(_vm.cornerModel, "status", $$v)},expression:"cornerModel.status"}})],1),_c('div',{staticClass:"flex mt-2"},[_c('div',{staticClass:"title"},[_vm._v("角标文本")]),_c('el-input',{staticStyle:{"width":"96px"},attrs:{"size":"mini","maxlength":"5"},model:{value:(_vm.cornerModel.content),callback:function ($$v) {_vm.$set(_vm.cornerModel, "content", $$v)},expression:"cornerModel.content"}}),_c('span',{staticClass:"corner-tip"},[_vm._v("5个字以下")])],1),_c('div',{staticClass:"flex mt-2"},[_c('div',{staticClass:"title required"},[_vm._v("角标图片")]),_c('choiceImage',{attrs:{"viewUrl":_vm.cornerModel.pictureUrl},on:{"surePush":_vm.surePush}})],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.showEditDialog = false}}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.addCorner}},[_vm._v("保存")])],1)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-row table-header",staticStyle:{"padding-left":"38px"}},[_c('div',{staticStyle:{"width":"20%","text-align":"center"}},[_vm._v("名称")]),_c('div',{staticStyle:{"width":"20%"}},[_vm._v("角标设置")]),_c('div',{staticStyle:{"width":"30%"}},[_vm._v("排序")]),_c('div',{staticStyle:{"width":"17%"}},[_vm._v("操作")]),_c('div',{staticStyle:{"width":"13%"}},[_vm._v("状态")])])
}]

export { render, staticRenderFns }