<template>
  <div class="contatain" v-loading="pageLoading">
    <div class="tab">
      <!-- btn -->
      <div class="save">
        <!-- <el-button
          v-loading="loading"
          :disabled="loading"
          @click="hidden"
          size="mini"
          class="save-btn"
          type="primary"
          >隐藏</el-button
        >
        <el-button
          v-loading="loading"
          :disabled="loading"
          @click="delTab"
          class="save-btn"
          size="mini"
          type="danger"
          style="color: #f394ac"
          plain
          >删除</el-button
        > -->
        <el-button
          v-loading="loading"
          :disabled="loading"
          @click="save"
          class="save-btn"
          style="
            border: 1px solid rgba(250, 225, 238, 1);
            background: rgba(250, 225, 238, 1);
            color: #333;
          "
          type="warning"
          size="mini"
          >保存</el-button
        >
      </div>
      <!-- tab栏 -->
      <div v-for="(item, index) in model" :key="`a${index}`">
        <div
          class="tab-item"
          v-if="item.showType === 3"
          :class="curTabIndex === index ? 'tab-item-active' : ''"
          @click="curTabIndex = index"
        >
          {{ item.exhibitionName }}
        </div>
      </div>
      <!-- <div class="addImg" @click="addTab"></div> -->
    </div>
    <div
      v-show="indexs === curTabIndex"
      style="
        width: 100%;
        flex: 1;
        overflow-y: auto;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 12px 0px;
      "
      v-for="(tabItem, indexs) in model"
      :key="indexs"
    >
      <div class="content">
        <div class="item">
          <div class="label">展区排序</div>
        </div>
        <div class="item">
          <div class="label">名称</div>
          <el-input
            size="mini"
            v-model="tabItem.exhibitionName"
            style="width: 96px"
            maxlength="4"
          ></el-input>
          <span
            style="
              color: #f394ac;
              margin-left: 20px;
              font-size: 14px;
              border: #fff;
            "
            >4字或以下</span
          >
          <myswitch
            :leftText="`显示`"
            :rightText="`隐藏`"
            :leftValue="1"
            :rightValue="0"
            v-model="hide"
            style="margin-left: 30px"
          />
        </div>
        <div class="item">
          <div class="label">展示方式</div>
          <el-button-group style="overflow: hidden; border-radius: 10px">
            <el-button
              round
              v-for="item in showTypeList"
              :key="item.id"
              @click="changeShowType(item.id)"
              :type="tabItem.showType === item.id ? 'primary' : ''"
              size="mini"
              >{{ item.name }}</el-button
            >
          </el-button-group>
        </div>
        <div>
          <div class="label">内容</div>
          <scriptCard
            v-if="model[curTabIndex].showType === 3 && indexs === curTabIndex"
            :scriptSortList="tabItem.exhibitionBannerRelevanceVOList"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import scriptCard from "./compts/scriptCard";
export default {
  data() {
    return {
      // tab栏
      curTabIndex: 0,
      tabMenu: [
        { name: "热门剧本", id: 0 },
        { exhibitionName: "最新榜单", id: 1 },
      ],
      showTypeList: [{ id: 3, name: "剧本卡片" }],
      showType: 3,
      bannerIndex: 0,
      loading: false,
      pageLoading: false,
      scenarioModel: {
        isAudit: 0,
        searchName: "",
        time: "",
        page: 1,
        limit: 50,
      },
      hide: 1,
      model: [
        {
          id: "",
          exhibitionName: "热门剧本", //展区名称
          showType: 2, //展示方式
          sort: "",
          exhibitionBannerRelevanceVOList: [
            {
              id: "",
              bannerPictureId: "", //banner图片ID
              bannerPicturePath: "", //banner图片地址
              skipUrl: "", //跳转路由
              skipType: 1, //跳转类型
              popupPictureId: "", //弹窗图片ID
              popupPicturePath: "", //弹窗图片地址
              popupContent: "", //弹窗内容
              sort: "",
              scenarioId: "",
              time: new Date().getTime(),
            },
          ],
        },
      ],
    };
  },
  components: {
    scriptCard
  },
  computed: {
    ...mapState(["scenarioList"]),
  },
  mounted() {
    // await this.getScenarioAll()
    this.getBannerMsg();
    this.getScenarioAll();
  },
  methods: {
    async getScenarioAll() {
      const res = await this.getRequest.getScenarioAll(this.scenarioModel);
      this.$store.commit("setScenarioList", res.data);
    },
    changeShowType() {
    //   this.$confirm("是否切换展示方式? 切换会清空此展区数据", "提示", {
    //     confirmButtonText: "确定",
    //     cancelButtonText: "取消",
    //     type: "warning",
    //   }).then(() => {
    //     this.model[this.curTabIndex].showType = type;
    //     this.model[this.curTabIndex].exhibitionBannerRelevanceVOList = [];
    //   });
    },
    addTab() {
      const newItem = this.$options.data().model[0];
      this.model.push(newItem);
      this.curTabIndex = this.model.length - 1;
    },
    hidden() {
      this.$message.warning("该功能仍在开发中,敬请期待");
    },
    delTab() {
      if (this.model.length === 1) {
        this.$message.error("不能删除最后一项");
        return;
      }

      this.$confirm("是否删除此Tab?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        const id = this.model[this.curTabIndex].id;
        if (id) {
          const res = await this.getRequest.delBannerMsg(id);
          if (res.respCode === 0) {
            this.model.splice(this.curTabIndex, 1);
            this.curTabIndex = --this.curTabIndex;
            this.$message.success(res.respMsg);
          }
        } else {
          this.model.splice(this.curTabIndex, 1);
          this.curTabIndex = --this.curTabIndex;
          this.$message.success("删除成功");
        }
      });
    },
    moveTab(movetype) {
      const arr = this.model;
      let index = this.curTabIndex;
      this._move(arr, movetype, index);
      switch (movetype) {
        case "toFirst": {
          this.curTabIndex = 0;
          break;
        }
        default: {
          this.curTabIndex =
            movetype === "pre"
              ? index
                ? --index
                : index
              : index === this.model.length - 1
              ? index
              : ++index;
        }
      }
    },
    _move(arr, movetype, index) {
      const [item] = arr.splice(index, 1);
      if (movetype !== "toFirst") {
        arr.splice(movetype === "pre" ? index - 1 : index + 1, 0, item);
        return;
      }
      arr.unshift(item);
    },
    async save() {
      const data = this.model[this.curTabIndex];
      data.sort = this.curTabIndex;
      data.exhibitionBannerRelevanceVOList.forEach((item, index) => {
        item.sort = index;
      });
      const res = await this.getRequest.changeBanner(data);
      if (res.respCode === 0) {
        this.getBannerMsg();
        this.$message.success(res.respMsg);
      }
    },
    async getBannerMsg() {
      this.pageLoading = true
      try {
        const res = await this.getRequest.getBannerMsg();
        if (res.respCode === 0) {
          if (res.datas.length) {
            this.model = res.datas;
            this.curTabIndex = res.datas.findIndex(item => item.showType === 3)
          }
        }
      } finally {
         this.pageLoading = false
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.contatain {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}
.save {
  z-index: 1000;
  position: absolute;
  bottom: 5px;
  right: 0px;
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  .save-btn {
    width: 96px;
    color: #fff;
    font-size: 16px;
    margin-left: 20px;
    font-weight: 500;
  }
}
.addImg {
  width: 18px;
  height: 24px;
  background-image: url("../../assets/add.png");
  cursor: pointer;
}
.banner-img-choice {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  background: #fff;
  border: 1px solid #c5c0e0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #333333;
  font-family: "SourceHanSansCN-Regular, SourceHanSansCN";
}
.flex-around {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.flex {
  display: flex;
}
.flex-start {
  display: flex;
  align-items: center;
}
.center {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 100px;
}
.first-title {
  color: #333333;
  font-size: 14px;
  margin-right: 20px;
}
.tab {
  display: flex;
  margin-bottom: 8px;
  &-item {
    width: 96px;
    height: 24px;
    border-radius: 4px;
    font-size: 18px;
    text-align: center;
    font-weight: 400;
    line-height: 24px;
    color: #000000;
    cursor: pointer;
    margin-right: 35px;
  }
  &-item-active {
    color: #fff;
    background: #7f76bd;
  }
}
.content {
  flex: 1;
  background: #fff;
  width: 100%;

  // margin-top: 8px;
  // height: calc(100% - 28px);
  height: 100%;
  box-sizing: border-box;
  padding: 32px 8px;
  color: #333333;
  overflow-y: auto;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 12px 0px;
  .item {
    display: flex;
    margin-bottom: 8px;
    align-items: center;
  }
  .label {
    width: 80px;
    height: 24px;
    background: #eceafa;
    border-radius: 4px;
    font-size: 18px;
    text-align: center;
    margin-right: 28px;
  }
}
.item-image {
  width: 149px;
  height: 56px;
  background: rgba(236, 234, 250, 1);
  border-radius: 4px;
  margin-top: 10px;
  position: relative;
  cursor: pointer;
  .left {
    position: absolute;
    top: -5px;
    left: 0;
  }
  .right {
    position: absolute;
    top: 0;
    right: 0;
    height: 20px;
    width: 54px;
    font-size: 14px;
    color: #fff;
    text-align: center;
    line-height: 20px;
    border-radius: 4px;
  }
  .item-image-mask {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgb(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 4px;
    color: #fff;
  }
}
v-deep .el-textarea__inner {
  margin: 0;
}
</style>